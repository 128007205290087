import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";
import { PlatformNavigationShell } from "../../components/ui/template/PlatformNavigationShell";

const LoginScreen = lazy(
  () => import("../../pages/authentication/LoginScreen")
);
const RegisterationScreen = lazy(
  () => import("../../pages/authentication/RegisterationScreen")
);
const PlatformHomeScreen = lazy(
  () => import("../../pages/platform/PlatformHome")
);
const MarketHomeScreen = lazy(
  () => import("../../pages/market/MarketHomeScreen")
);
const ShopHomeScreen = lazy(
  () => import("../../pages/market/LocalStoreHomeScreen")
);
const ProductHomeScreen = lazy(
  () => import("../../pages/market/ProductHomeScreen")
);
const MarketSearchResultScreen = lazy(
  () => import("../../pages/market/MarketSearchResult")
);

export default function MainAppRoutes() {
  useScrollToTop();
  return (
    <Routes>
      <Route path="/" element={<PlatformNavigationShell />}>
        <Route path="about" element={<div></div>} />
        <Route path="contact" element={<div></div>} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="register" element={<RegisterationScreen />} />
        <Route path="store/:shopRef">
          <Route index element={<ShopHomeScreen />} />
          <Route path="products/:productRef" element={<ProductHomeScreen />} />
        </Route>
        <Route path="market">
          <Route path=":marketRef" element={<MarketHomeScreen />} />
        </Route>
        <Route path="search" element={<MarketSearchResultScreen />} />
        <Route index element={<PlatformHomeScreen />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
