import { NonAuthPath } from "../../Enums/NonAuthPath";
import { Helper } from "../../utils/helper";
import MarketplaceHeader from "./MarketplaceHeader";
import NonAuthMarketplaceHeader from "./NonAuthMarketplaceHeader";

export default function Header({
  opened,
  setOpened,
}: {
  setOpened: (data: any) => void;
  opened: boolean;
}) {
  const result = Helper.isPageActive([
    NonAuthPath.SIGNUP,
    NonAuthPath.LOGIN,
    NonAuthPath.RESETPASSWORD,
    NonAuthPath.VERIFYEMAIL,
  ]);
  return result ? (
    <NonAuthMarketplaceHeader opened={opened} setOpened={setOpened} />
  ) : (
    <MarketplaceHeader opened={opened} setOpened={setOpened} />
  );
}
