import { useState } from "react";
import { AppShell, useMantineTheme } from "@mantine/core";
import Sidebar from "../sidebar-navigation/Sidebar";

type MainAppShellProps = {
  children: any;
  ShellHeader: React.FC<{ setOpened: (data: any) => void; opened: boolean }>;
  ShellFooter: React.FC<any>;
};

export const MainAppShell: React.FC<MainAppShellProps> = ({
  children,
  ShellFooter,
  ShellHeader,
}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.darkBlue[5]
              : theme.colors.gray[0],
          paddingLeft: 0,
          paddingRight: 0,
          marginTop: 0,
          paddingTop: 60,
        },
      }}
      header={<ShellHeader opened={opened} setOpened={setOpened} />}
      footer={<ShellFooter />}
      navbar={<Sidebar opened={opened} setOpened={setOpened} />}
    >
      <div>{children}</div>
    </AppShell>
  );
};

export default MainAppShell;
