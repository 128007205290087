import React from "react";
import { createContext } from "react";
import useProductContextStateAndActions from "./useProductContextStateAndActions";

type ProductContextType = ReturnType<typeof useProductContextStateAndActions>;

export const ProductContext = createContext({} as ProductContextType);

export const ProductProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ProductContext.Provider value={useProductContextStateAndActions()}>
      {children}
    </ProductContext.Provider>
  );
};
