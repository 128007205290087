import {
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Box,
  Group,
  Container,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { logo } from "../../assets/icons";

const MarketplaceHeader: React.FC<{
  setOpened: (data: any) => void;
  opened: boolean;
}> = ({ opened, setOpened }) => {
  let theme = useMantineTheme();
  return (
    <Header
      height={60}
      sx={(theme) => {
        return { borderBottom: "0px solid gray", boxShadow: theme.shadows.md };
      }}
    >
      <Container size={"lg"} sx={{ height: "100%" }}>
        <Group
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "justify-between",
            alignItems: "center",
          }}
          position="apart"
        >
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={opened}
              onClick={() => setOpened((o: any) => !o)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Box
              sx={() => {
                return {
                  height: "100%",
                  width: "250px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                };
              }}
              p="md"
            >
              <Link to={"/"}>
                <img src={logo} alt="React Logo" style={{ height: 40 }} />
              </Link>
            </Box>
          </MediaQuery>
        </Group>
      </Container>
    </Header>
  );
};

export default MarketplaceHeader;
