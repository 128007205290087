import { Navbar, ScrollArea } from "@mantine/core";
import { NavigationLinks } from "./NavigationLinks";
import { useStyles } from "./style";

const Sidebar: React.FC<{
  setOpened: (data: any) => void;
  opened: boolean;
}> = ({ opened, setOpened }) => {
  const { classes } = useStyles();
  return opened ? (
    <Navbar
      height={"calc(100vh - 70px)"}
      width={{ sm: 250 }}
      p="md"
      className={classes.navbar}
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>
          <NavigationLinks setOpened={setOpened} />
        </div>
      </Navbar.Section>
    </Navbar>
  ) : (
    <></>
  );
};

export default Sidebar;
