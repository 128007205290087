import { useCallback, useState } from "react";
import { ServerProductPayload } from "../../types";

export default function useProductContextStateAndActions() {
  const [products, setProducts] = useState<
    ServerProductPayload[] | undefined
  >();
  const setProductsHandler = useCallback(
    (payload: ServerProductPayload[] | undefined) => {
      setProducts(payload);
    },
    []
  );
  return { products, setProductsHandler };
}
