import {
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Text,
  Box,
  Group,
  Button,
  Container,
} from "@mantine/core";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Helper } from "../../utils/helper";
import { logo } from "../../assets/icons";

const MarketplaceHeader: React.FC<{
  setOpened: (data: any) => void;
  opened: boolean;
}> = ({ opened, setOpened }) => {
  let theme = useMantineTheme();
  return (
    <Header
      height={60}
      sx={(theme) => {
        return { borderBottom: "0px solid gray", boxShadow: theme.shadows.md };
      }}
    >
      <Container size={"lg"} sx={{ height: "100%" }}>
        <Group
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          align="center"
          position="apart"
        >
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Group>
              <Burger
                opened={opened}
                onClick={() => setOpened((o: any) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xs"
              />
              <img src={logo} alt="React Logo" style={{ height: 20 }} />
            </Group>
          </MediaQuery>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Box
              sx={() => {
                return {
                  height: "100%",
                  width: "250px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                };
              }}
              p="md"
            >
              <Link to={"/"}>
                <img src={logo} alt="React Logo" style={{ height: 40 }} />
              </Link>
            </Box>
          </MediaQuery>
          {/* <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Box>
              <Group position="center">
                {[
                  { label: "Home", path: "/" },
                  { label: "About", path: "/about" },
                  { label: "Contact Us", path: "/contact" },
                ].map((element, index) => {
                  return (
                    <Link
                      key={element.path + index}
                      to={element.path}
                      style={{ textDecoration: "none", color: "#404040" }}
                    >
                      <Text
                        sx={{
                          ":hover": { color: "#049ACE" },
                          fontWeight: "bolder",
                        }}
                      >
                        {element.label}
                      </Text>
                    </Link>
                  );
                })}
              </Group>
            </Box>
          </MediaQuery> */}
          {!Helper.getUserFromLocalstorage()?.token ? (
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Group align={"center"}>
                <Link to={"/login"}>
                  <Button>Log In</Button>
                </Link>
                <Button
                  onClick={() =>
                    window.open("https://admin.zmarket.ng/register", "_blank")
                  }
                  sx={{ backgroundColor: "#183B56", marginLeft: "auto" }}
                  variant="filled"
                >
                  Become A Seller
                </Button>
              </Group>
            </MediaQuery>
          ) : (
            <Group>
              <Button onClick={Helper.clearLocalStorage}>Log Out</Button>
            </Group>
          )}
        </Group>
      </Container>
    </Header>
  );
};

export default observer(MarketplaceHeader);
