import { SomethingWentWrongWidget } from "../SomethingWentWrongWidget";
import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../Header";
import MarketplaceFooter from "../MarketplaceFooter";
import MainAppShell from "./MainAppShell";

export class PlatformNavigationShell extends React.Component<
  {},
  { error: null | undefined | boolean | any; errorInfo: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: "" };
  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    return (
      <MainAppShell ShellHeader={Header} ShellFooter={MarketplaceFooter}>
        {this.state.error ? <SomethingWentWrongWidget /> : <Outlet />}
      </MainAppShell>
    );
  }
}
