import React from "react";
import { createContext } from "react";
import useContextStateAndActions from "./useContextStateAndActions";

type EventContextType = ReturnType<typeof useContextStateAndActions>;

export const EventContext = createContext({} as EventContextType);

export const EventProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <EventContext.Provider value={useContextStateAndActions()}>
      {children}
    </EventContext.Provider>
  );
};
