import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { LoadingOverlay, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import MainAppRoutes from "./router/MainAppRoutes";
import { MaintineThemeConfig } from "../styles/mantine-config";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../config/queryClient";
import { EventProvider } from "../context/event/eventContext";
import { ProductProvider } from "../context/product/productEvent";

export default function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <MantineProvider
            theme={MaintineThemeConfig}
            withGlobalStyles
            withNormalizeCSS
          >
            <NotificationsProvider>
              <EventProvider>
                <ProductProvider>
                  <Suspense fallback={<LoadingOverlay visible={true} />}>
                    <MainAppRoutes />
                  </Suspense>
                </ProductProvider>
              </EventProvider>
            </NotificationsProvider>
          </MantineProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.Fragment>
  );
}
